import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { FormatUnderlined } from "@material-ui/icons";
import fonts from "./fonts";
import commonSettings from "./global.js";

// TODO: Break repeated use color values out into list of consts declared here
// then set the values in darkTheme using the global color variables

const darkTheme = {
    color: "#24d5d3",
    gold: "#ed8c79",
    white: "#ffffff",
    gray: "#A3A3A3",
    clickable: "#d4af37",
    clickhover: "#b29700",
    clickactive: "#24d5d3",
    textHighlightColor: "#0d8ca5",
    backgroundColor: "#000",
    background: `#92D050`,
    paperBg: "none",
    modalBg: "#24242699",
    popoverBg: "rgba(54, 56, 64, 0.99)",
    menuBg: "#36384080",
    backdropBg: "rgba(54, 56, 64, 0.5)",
    largeTextColor: "#0d8ca5",
    activeLinkColor: "#F5DDB4",
    activeLinkSvgColor: "brightness(0) saturate(100%) invert(84%) sepia(49%) saturate(307%) hue-rotate(326deg) brightness(106%) contrast(92%)",
    primaryButtonColor: "#ffffff",
    primaryButtonBG: "#0d8ca5",
    primaryButtonHoverBG: "#EDD8B4",
    secondaryButtonHoverBG: "rgba(54, 56, 64, 1)",
    outlinedPrimaryButtonHoverBG: "#d4af37",
    outlinedPrimaryButtonHoverColor: "none",
    outlinedSecondaryButtonHoverBG: "#1a6577",
    outlinedSecondaryButtonHoverColor: "#d4af37", //gold
    containedSecondaryButtonHoverBG: "rgba(255, 255, 255, 0.15)",
    graphStrokeColor: "rgba(255, 255, 255, .1)",
};

export const dark = responsiveFontSizes(
    createTheme({
            primary: {
                main: darkTheme.color,
            },
            palette: {
                type: "dark",
                background: {
                    default: darkTheme.backgroundColor,
                    paper: darkTheme.paperBg,
                },
                contrastText: darkTheme.color,
                primary: {
                    main: darkTheme.color,
                },
                neutral: {
                    main: darkTheme.color,
                    secondary: darkTheme.gray,
                },
                text: {
                    primary: darkTheme.color,
                    secondary: darkTheme.gray,
                },
                graphStrokeColor: darkTheme.graphStrokeColor,
            },
            typography: {
                fontFamily: "Outfit",
            },
            props: {
                MuiSvgIcon: {
                    htmlColor: darkTheme.color,
                },
            },
            overrides: {
                MuiCssBaseline: {
                    "@global": {
                        "@font-face": fonts,
                        body: {
                            background: darkTheme.background,
                        },
                    },
                },
                MuiDrawer: {
                    paper: {
                        backgroundColor: darkTheme.paperBg,
                        zIndex: 7,
                    },
                },
                MuiPaper: {
                    root: {
                        backgroundColor: darkTheme.paperBg,
                        "&.ohm-card": {
                            backgroundColor: darkTheme.paperBg,
                        },
                        "&.ohm-modal": {
                            backgroundColor: darkTheme.modalBg,
                        },
                        "&.ohm-menu": {
                            backgroundColor: darkTheme.menuBg,
                            backdropFilter: "blur(33px)",
                        },
                        "&.ohm-popover": {
                            backgroundColor: darkTheme.popoverBg,
                            color: darkTheme.color,
                            backdropFilter: "blur(15px)",
                        },
                    },
                },
                MuiBackdrop: {
                    root: {
                        backgroundColor: darkTheme.backdropBg,
                    },
                },
                MuiLink: {
                    root: {
                        color: darkTheme.clickable,
                        "&:hover": {
                            color: darkTheme.clickhover,
                            textDecoration: "none",
                            "&.active": {
                                color: darkTheme.clickactive,
                            },
                        },
                        "&.active": {
                            color: darkTheme.clickactive,
                            textDecoration: "none",
                        },
                    },
                },
                MuiTableCell: {
                    root: {
                        color: darkTheme.clickactive,
                    },
                },
                MuiInputBase: {
                    root: {
                        // color: darkTheme.gold,
                    },
                },
                MuiOutlinedInput: {

                    notchedOutline: {
                        border: `0.5px solid #d4af34 !important`,
                        "&:hover": {
                            border: `0.5px solid #b29700 !important`,
                        },
                        "&:active": {
                            border: `0.5px solid #b29700 !important`,
                        },
                    },
                },
                MuiTab: {
                    textColorPrimary: {
                        color: darkTheme.gray,
                        "&$selected": {
                            color: darkTheme.gold,
                            textDecoration: "none",
                        },
                    },
                },
                PrivateTabIndicator: {
                    colorPrimary: {
                        backgroundColor: darkTheme.gold,
                    },
                },
                MuiToggleButton: {
                    root: {
                        backgroundColor: darkTheme.paperBg,
                        "&:hover": {
                            color: darkTheme.color,
                            backgroundColor: `${darkTheme.containedSecondaryButtonHoverBG} !important`,
                        },
                        selected: {
                            backgroundColor: darkTheme.containedSecondaryButtonHoverBG,
                        },
                        "@media (hover:none)": {
                            "&:hover": {
                                color: darkTheme.color,
                                backgroundColor: darkTheme.paperBg,
                            },
                            "&:focus": {
                                color: darkTheme.color,
                                backgroundColor: darkTheme.paperBg,
                                borderColor: "#1a6577",
                                outline: "#00000000",
                            },
                        },
                    },
                },
                MuiButton: {
                    containedPrimary: {
                        color: darkTheme.primaryButtonColor,
                        backgroundColor: darkTheme.clickable,
                        "&:hover": {
                            backgroundColor: darkTheme.clickhover,
                            color: darkTheme.white,
                            borderColor: "none",
                        },
                        "&:active": {
                            backgroundColor: darkTheme.clickactive,
                            color: darkTheme.white,
                        },
                        "@media (hover:none)": {
                            color: darkTheme.white,
                            backgroundColor: darkTheme.clickhover,
                            "&:hover": {
                                backgroundColor: darkTheme.clickhover,
                            },
                        },
                    },
                    containedSecondary: {
                        backgroundColor: darkTheme.paperBg,
                        color: darkTheme.color,
                        "&:hover": {
                            backgroundColor: `${darkTheme.containedSecondaryButtonHoverBG} !important`,
                        },
                        "&:active": {
                            backgroundColor: darkTheme.containedSecondaryButtonHoverBG,
                        },
                        "&:focus": {
                            backgroundColor: darkTheme.paperBg,
                        },
                        "@media (hover:none)": {
                            color: darkTheme.color,
                            backgroundColor: darkTheme.paperBg,
                            "&:hover": {
                                backgroundColor: `${darkTheme.containedSecondaryButtonHoverBG} !important`,
                            },
                        },
                    },
                    outlinedPrimary: {
                        color: darkTheme.gold,
                        borderColor: darkTheme.gold,
                        "&:hover": {
                            color: darkTheme.outlinedPrimaryButtonHoverColor,
                            backgroundColor: darkTheme.primaryButtonHoverBG,
                        },
                        "@media (hover:none)": {
                            color: darkTheme.gold,
                            borderColor: darkTheme.gold,
                            "&:hover": {
                                color: darkTheme.outlinedPrimaryButtonHoverColor,
                                backgroundColor: `${darkTheme.primaryButtonHoverBG} !important`,
                                textDecoration: "none !important",
                            },
                        },
                    },
                    outlinedSecondary: {
                        color: darkTheme.color,
                        borderColor: darkTheme.color,
                        "&:hover": {
                            color: darkTheme.outlinedSecondaryButtonHoverColor,
                            backgroundColor: darkTheme.outlinedSecondaryButtonHoverBG,
                            borderColor: darkTheme.gold,
                        },
                    },
                    textPrimary: {
                        color: "#A3A3A3",
                        "&:hover": {
                            color: darkTheme.gold,
                            backgroundColor: "#00000000",
                        },
                        "&:active": {
                            color: darkTheme.gold,
                            borderBottom: "#d4af37",
                        },
                    },
                    textSecondary: {
                        color: darkTheme.color,
                        "&:hover": {
                            color: darkTheme.textHighlightColor,
                        },
                    },
                },
            },
        },
        commonSettings,
    ),
);