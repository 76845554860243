export const THE_GRAPH_URL = "https://api.thegraph.com/subgraphs/name/drondin/olympus-graph";
export const EPOCH_INTERVAL = 9600;

// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 3;

export const TOKEN_DECIMALS = 18;

export const POOL_GRAPH_URLS = {
  4: "https://api.thegraph.com/subgraphs/name/pooltogether/rinkeby-v3_4_3",
  1: "https://api.thegraph.com/subgraphs/name/pooltogether/pooltogether-v3_4_3",
};

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  5 : {
    CHITFUND_ADDRESS : "0x8b77DfCC3f19e73A484E5086fC97B48c31B80b11",
    // "0xE37c40dc5fcD53EA56eF55CDFD077A12dEDbd1b6",
    CHITTOKEN_ADDRESS : "0xeF78a9Bb2c5dF0484A62eABa6f37218E2394c7Af"
  }
};
