import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as ConvertAbi } from "../abi/PresaleTokenConvert.json";
import { abi as sWand } from "../abi/sWand.json";
import { setAll } from "../helpers";
import { abi as luckblock } from "../abi/luckblock.json";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { Bond, NetworkID } from "src/lib/Bond"; // TODO: this type definition needs to move out of BOND.
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk, ICalcUserBondDetailsAsyncThunk } from "./interfaces";

export const getBalances = createAsyncThunk(
  "account/getBalances",
  async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {


    const lbtokencontract = new ethers.Contract(addresses[networkID].CHITTOKEN_ADDRESS as string, ierc20Abi, provider)

    const accountLBbalance = await lbtokencontract.balanceOf(address);
    // console.log('swandContract33',swandBalance)
    // const poolTokenContract = new ethers.Contract(addresses[networkID].PT_TOKEN_ADDRESS as string, ierc20Abi, provider);
    // poolBalance = await poolTokenContract.balanceOf(address);

    return {
      balances: {
        accountLBbalance: ethers.utils.formatUnits(accountLBbalance, "gwei")
      },
    };
  },
);

export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
    let accountLBbalance = 0;
    const lbtokencontract = new ethers.Contract(addresses[networkID].CHITTOKEN_ADDRESS as string, ierc20Abi, provider)

    accountLBbalance = await lbtokencontract.balanceOf(address);
    const luckblockContract = new ethers.Contract(addresses[networkID].CHITFUND_ADDRESS as string, luckblock, provider)
    const pool1AccountTicketbalance = await luckblockContract.getNumberOfTicketsPurchased(address, 0);
    const pool2AccountTicketbalance = await luckblockContract.getNumberOfTicketsPurchased(address, 1);
    const pool3AccountTicketbalance = await luckblockContract.getNumberOfTicketsPurchased(address, 2);
    const accountTicketID = [];
    for(let i = 0; i< pool1AccountTicketbalance.length; i++)
    {
      accountTicketID.push(Number(pool1AccountTicketbalance[i]));
    }
    for(let i = 0; i< pool2AccountTicketbalance.length; i++)
    {
      accountTicketID.push(Number(pool2AccountTicketbalance[i]));
    }
    for(let i = 0; i< pool3AccountTicketbalance.length; i++)
    {
      accountTicketID.push(Number(pool3AccountTicketbalance[i]));
    }
    const buyAllowance =  await lbtokencontract.allowance(address, addresses[networkID].CHITFUND_ADDRESS);

    return {
      accountLBbalance: ethers.utils.formatUnits(accountLBbalance, "gwei"),
      accountTicketID,
      pool1AccountTicketbalance,
      pool2AccountTicketbalance,
      pool3AccountTicketbalance,
      buyAllowance

    };
  },
);

export interface IUserBondDetails {
  allowance: number;
  interestDue: number;
  bondMaturationBlock: number;
  pendingPayout: string; //Payout formatted in gwei.
}
export const calculateUserBondDetails = createAsyncThunk(
  "account/calculateUserBondDetails",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
    if (!address) {
      return {
        bond: "",
        displayName: "",
        bondIconSvg: "",
        isLP: false,
        allowance: 0,
        balance: "0",
        interestDue: 0,
        bondMaturationBlock: 0,
        pendingPayout: "",
      };
    }
    // dispatch(fetchBondInProgress());

    // Calculate bond details.
    const bondContract = bond.getContractForBond(networkID, provider);
    const reserveContract = bond.getContractForReserve(networkID, provider);

    const busdContract = new ethers.Contract(addresses[networkID].BUSD_ADDRESS as string, ierc20Abi, provider);
    let interestDue, pendingPayout, bondMaturationBlock;



    const bondDetails = await bondContract.bondInfo(address);
    interestDue = bondDetails.payout / Math.pow(10, 9);
    bondMaturationBlock = +bondDetails.vesting + +bondDetails.lastBlock;
    pendingPayout = await bondContract.pendingPayoutFor(address);

    let allowance,
      balance = 0;
    allowance = await reserveContract.allowance(address, bond.getAddressForBond(networkID));
    balance = await reserveContract.balanceOf(address);
    // formatEthers takes BigNumber => String
    // let balanceVal = ethers.utils.formatEther(balance);
    // balanceVal should NOT be converted to a number. it loses decimal precision
    let deciamls = 18;
    if (bond.name == "usdc") {
      deciamls = 6;
    }
    const balanceVal = balance / Math.pow(10, deciamls);
    return {
      bond: bond.name,
      displayName: bond.displayName,
      bondIconSvg: bond.bondIconSvg,
      isLP: bond.isLP,
      allowance: Number(allowance),
      balance: balanceVal.toString(),
      interestDue,
      bondMaturationBlock,
      pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
    };
  },
);

interface IAccountSlice {
  bonds: { [key: string]: IUserBondDetails };
  balances: {
    wand: string;
    swand: string;
    busd: string;
    dai: string;
  };
  loading: boolean;
}
const initialState: IAccountSlice = {
  loading: false,
  bonds: {},
  balances: { wand: "", swand: "", busd: "", dai: "" },
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getBalances.pending, state => {
        state.loading = true;
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBalances.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      // .addCase(calculateUserBondDetails.pending, state => {
      //   state.loading = true;
      // })
      // .addCase(calculateUserBondDetails.fulfilled, (state, action) => {
      //   if (!action.payload) return;
      //   const bond = action.payload.bond;
      //   state.bonds[bond] = action.payload;
      //   state.loading = false;
      // })
      // .addCase(calculateUserBondDetails.rejected, (state, { error }) => {
      //   state.loading = false;
      //   console.log(error);
      // });
  },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
