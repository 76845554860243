// import { useState } from "react";
import * as React from "react";
import { Paper, Grid, Typography, Box, Zoom, Container, SvgIcon, useMediaQuery } from "@material-ui/core";
// import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
// import { ReactComponent as WalletIcon } from "../../assets/icons/wallet.svg";
// import {
//   treasuryDataQuery,
//   rebasesDataQuery,
//   bulletpoints,
//   tooltipItems,
//   tooltipInfoMessages,
//   itemType,
// } from "./treasuryData.js";
// import { useTheme } from "@material-ui/core/styles";
import "../Home/main.scss"
// import apollo from "../../lib/apolloClient";
// import RebaseTimer from "../../components/RebaseTimer/RebaseTimer";
import { useTable } from 'react-table';
// import styled from 'styled-components';
import makeData from "../../views/Ticketwallet_history/makeData";

// const Styles = styled.div`
//   width : 80%;
//   margin: auto;
//   height: 150px;
//   overflow-x : auto;
//   margin-bottom: 40px;
//   table {
//     width : 100%;
//     margin: auto;
//     border-spacing: 0;
//     border: 1px solid white;
//     tr {
//       :last-child {
//         td {
//           border-bottom: 0;
//         }
//       }
//     }
//     th,
//     td {
//       margin: 0;
//       padding: 0.5rem;
//       border-bottom: 1px solid white;
//       border-right: 1px solid white;
//       :last-child {
//         border-right: 0;
//       }
//     }
//   }
// `

function TreasuryDashboard() {
  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: 'TICKET NO',
  //       accessor: 'firstName',
  //     },
  //     {
  //       Header: 'STATUS',
  //       accessor: 'lastName',
  //     },
  //     {
  //       Header: 'DATE',
  //       accessor: 'age',
  //     },
  //     {
  //       Header: 'RESULT',
  //       accessor: 'visits',
  //     },
  //   ],
  //   []
  // )
  // const theme = useTheme();
  const smallerScreen = useMediaQuery("(max-width: 650px)");
  const verySmallScreen = useMediaQuery("(max-width: 379px)");

  const accountLBbalance = useSelector(state => {
    return state.account.accountLBbalance;
  });

  const accountTicketID = useSelector(state => {
    return state.account.accountTicketID;
  });
  // const endTime = useSelector(state => {
  //   return state.app.epoch;
  // });
  const history = useSelector(state => {
    return state.app.history;
  })
  const rewardAmount =  useSelector(state => {
    return state.app.rewardAmount;
  });
  let rewardAmountWinner = Number(rewardAmount)/1000000000000000000 ;

  return (
    <div id="treasury-dashboard-view" className={`${smallerScreen && "smaller"} ${verySmallScreen && "very-small"}`}>
      <Container
        style={{
          paddingLeft: smallerScreen || verySmallScreen ? "0" : "3.3rem",
          paddingRight: smallerScreen || verySmallScreen ? "0" : "3.3rem",
        }}
      >
        <Box className={`hero-metrics`}>
          <Paper className="ohm-card">
          <Box className="empty-card">
             {/* <SvgIcon
               color="primary"
               component={WalletIcon}
               viewBox="0 0 1280 720"
               style={{ width: "300px", height: "120px", justifyContent: "center", display:"flex" ,alignContent:"center", alignItems:"center"}}
             /> */}
          </Box>
         <Box className='medium-card'>
           {/* <Box className="small-card-top">
             <h1 className="title">Next Draw in</h1>
             <RebaseTimer />
           </Box> */}

           <Box className="small-card-middle2">
             <h1 className="title">Total Prizes</h1>
             <h2 className="price">{rewardAmountWinner} DAI</h2>
           </Box>
           <Box className="small-card-middle">
              <h1 className="title">My Ticket Balance</h1>
              <h2 className="price">
                {accountTicketID ? (
                  parseInt(accountTicketID.length)
                ) : (
                  0
                )}
              </h2>
            </Box>
            <Box className="small-card-bottom">
              <h1 className="title">My DAI Balance</h1>
              <h2 className="price">           
               {accountLBbalance ? (
                   parseFloat(accountLBbalance / 1000000000).toFixed(3)
                 ) : (
                   0
                 )} DAI
              </h2>   
            </Box>        
            <div className="single-button">
              <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xdc31ee1784292379fbb2964b3b9c4124d8f89c60" target={"_blank"} class='buybutton_home'>Swap ETH/DAI</a>
            </div>
          </Box>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}


function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns, data
  })
  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default TreasuryDashboard;
