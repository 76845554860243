
import { ethers } from "ethers";
import { addresses } from "../constants";
// import { abi as OlympusStaking } from "../abi/OlympusStaking.json";
// import { abi as OlympusStakingv2 } from "../abi/OlympusStakingv2.json";
// import { abi as sOHM } from "../abi/sOHM.json";
// import { abi as ierc20Abi } from "../abi/IERC20.json";
// import { abi as sOHMv2 } from "../abi/sOhmv2.json";
import { abi as luckblock } from "../abi/luckblock.json";
import { setAll, getTokenPrice, getMarketPrice } from "../helpers";
// import { NodeHelper } from "../helpers/NodeHelper";
// import apollo from "../lib/apolloClient.js";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";
// import { calcRunway } from "src/helpers/Runway";

const initialState = {
  loading: false,
  loadingMarketPrice: false,
};
export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
    const ChitfundContract = new ethers.Contract(addresses[networkID].CHITFUND_ADDRESS as string, luckblock, provider)
    const epoch1 = await ChitfundContract.getChitfundEndDate(0);
    const epoch2 = await ChitfundContract.getChitfundEndDate(1);
    const epoch3 = await ChitfundContract.getChitfundEndDate(2);
    const NumberofTicketSold =  await ChitfundContract.totalTicketSold();
    const history = await ChitfundContract.history();
    const lotteryStatus1 = await ChitfundContract.getCurrentChitfundTicketSalesStatus(0);
    const lotteryStatus2 = await ChitfundContract.getCurrentChitfundTicketSalesStatus(1);
    const lotteryStatus3 = await ChitfundContract.getCurrentChitfundTicketSalesStatus(2);
    const rewardAmount = await ChitfundContract.totalChitpotAmount();
    let getTimeLeft1;
    let getTimeLeft2;
    let getTimeLeft3;
    if(lotteryStatus1 != false)
    {
      getTimeLeft1 = await ChitfundContract.getTimeLeft(0);
    }else{
      getTimeLeft1 = 0;
    }
    if(lotteryStatus2 != false)
    {
      getTimeLeft2 = await ChitfundContract.getTimeLeft(1);
    }else{
      getTimeLeft2 = 0;
    }
    if(lotteryStatus3 != false)
    {
      getTimeLeft3 = await ChitfundContract.getTimeLeft(2);
    }else{
      getTimeLeft3 = 0;
    }
    return {
      epoch1,
      epoch2,
      epoch3,
      NumberofTicketSold,
      getTimeLeft1,
      getTimeLeft2,
      getTimeLeft3,
      history,
      rewardAmount
    } as unknown as IAppData;
  },
);

/**
 * checks if app.slice has marketPrice already
 * if yes then simply load that state
 * if no then fetches via `loadMarketPrice`
 *
 * `usage`:
 * ```
 * const originalPromiseResult = await dispatch(
 *    findOrLoadMarketPrice({ networkID: networkID, provider: provider }),
 *  ).unwrap();
 * originalPromiseResult?.whateverValue;
 * ```
 */
export const findOrLoadMarketPrice = createAsyncThunk(
  "app/findOrLoadMarketPrice",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch, getState }) => {
    const state: any = getState();
    let marketPrice;
    // check if we already have loaded market price
    if (state.app.loadingMarketPrice === false && state.app.marketPrice) {
      // go get marketPrice from app.state
      marketPrice = state.app.marketPrice;
    } else {
      // we don't have marketPrice in app.state, so go get it
      try {
        const originalPromiseResult = await dispatch(
          loadMarketPrice({ networkID: networkID, provider: provider }),
        ).unwrap();
        marketPrice = originalPromiseResult?.marketPrice;
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        console.error("Returned a null response from dispatch(loadMarketPrice)");
        return;
      }
    }
    return { marketPrice };
  },
);

/**
 * - fetches the OHM price from CoinGecko (via getTokenPrice)
 * - falls back to fetch marketPrice from ohm-dai contract
 * - updates the App.slice when it runs
 */
const loadMarketPrice = createAsyncThunk("app/loadMarketPrice", async ({ networkID, provider }: IBaseAsyncThunk) => {
  let marketPrice: number;
  try {
    marketPrice = await getMarketPrice({ networkID, provider });
    marketPrice = marketPrice / Math.pow(10, 9);
  } catch (e) {
    marketPrice = await getTokenPrice("hector");
  }
  return { marketPrice };
});

interface IAppData {
  readonly epoch3: number;
  readonly epoch1: number;
  readonly epoch2: number;
  readonly NumberofTicketSold: number;
  readonly getTimeLeft: number;
  readonly getChitfundEndDate: number;
  readonly history: number;
  // readonly circSupply: number;
  // readonly currentIndex?: string;
  // readonly currentBlock?: number;
  // readonly fiveDayRate?: number;
  // readonly oldfiveDayRate?: number;
  // readonly marketCap: number;
  // readonly marketPrice: number;
  // readonly stakingAPY?: number;
  // readonly stakingRebase?: number;
  // readonly old_stakingRebase?: number;
  // readonly stakingTVL: number;
  // readonly totalSupply: number;
  // readonly treasuryBalance?: number;
  // readonly endBlock?: number;
  // readonly busdTreasuyBalance?: number;
  // readonly busdPALTreasuyBalance?: number;
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error.name, error.message, error.stack);
      })
      .addCase(loadMarketPrice.pending, (state, action) => {
        state.loadingMarketPrice = true;
      })
      .addCase(loadMarketPrice.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loadingMarketPrice = false;
      })
      .addCase(loadMarketPrice.rejected, (state, { error }) => {
        state.loadingMarketPrice = false;
        console.error(error.name, error.message, error.stack);
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);

