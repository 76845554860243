import { SvgIcon, Link } from "@material-ui/core";
// import { ReactComponent as GitHub } from "../../assets/icons/github.svg";
// import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as SwapIcon } from "../../assets/icons/swap.svg";
// import { ReactComponent as OhoIcon } from "../../assets/icons/ohoicon.svg";
// import { ReactComponent as Youtube } from "../../assets/icons/youtube.svg";
import { ReactComponent as Telegram } from "../../assets/icons/telegram.svg";

// import { ReactComponent as facebook } from "../../assets/icons/facebook.svg";
// import { ReactComponent as youtube } from "../../assets/icons/youtube.svg";
// import { ReactComponent as instagram } from "../../assets/icons/instagram.svg";
// import { ReactComponent as reddit } from "../../assets/icons/reddit.svg";

export default function Social() {
  return (
    <div>
      <div className="social-row">
        <Link href="https://t.me/" target="_blank">
          <SvgIcon color="primary" component={Telegram} />
        </Link>
        <Link href="https://twitter.com/" target="_blank">
          <SvgIcon color="primary" component={Twitter} />
        </Link>

        {/* <Link href="https://oho.ai/" target="_blank">
          <SvgIcon color="primary" component={OhoIcon} />
        </Link> */}

        <Link href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xdc31ee1784292379fbb2964b3b9c4124d8f89c60" target="_blank">
        <SvgIcon color="primary" component={SwapIcon} />
        </Link>

        {/* <Link href="https://medium.com/" target="_blank">
          <SvgIcon color="primary" component={Medium} />
        </Link> */}

      </div>
    </div>

  );
}
