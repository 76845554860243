import { useSelector, useDispatch } from "react-redux";
import { getRebaseBlock, secondsUntilBlock, prettifySeconds } from "../../helpers";
import { Box, Typography } from "@material-ui/core";
import "./rebasetimer.scss";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useMemo, useState } from "react";
import { loadAppDetails } from "../../slices/AppSlice";
import { useWeb3Context } from "../../hooks/web3Context";

import Chart from "react-apexcharts";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Axios from "../../utils/axios";

function RebaseTimer2() {
  const dispatch = useDispatch();
  const { provider, chainID } = useWeb3Context();

  const SECONDS_TO_REFRESH = 60;
  const [secondsToRebase, setSecondsToRebase] = useState(0);
  const [rebaseString, setRebaseString] = useState("");
  const [secs, setSecs] = useState("");
  const [mins, setMins] = useState("");
  const [hours, setHours] = useState("");
  const [dates, setDays] = useState("");
  const [secondsToRefresh, setSecondsToRefresh] = useState(SECONDS_TO_REFRESH);
  const endBlock = useSelector(state => {
    return state.app.epoch2;
  });
  const endTimeLeft = useSelector(state => {
    return state.app.getTimeLeft2;
  });

  const currentBlock = Number((new Date().getTime() / 1000).toFixed(0));
  // if (Number(endBlock) < currentBlock) {
  //   Axios.post("/SetLottery", {})   // To set the lottery start
  //     .catch(error => {
  //       console.log(error);
  //     })
  // }

  let seconds;

  function initializeTimer() {
    const rebaseBlock = endBlock;
    seconds = Number(endBlock) - currentBlock;
    setSecondsToRebase(seconds);
    const prettified = prettifySeconds(seconds);
    setRebaseString(prettified !== "" ? prettified : "Please wait until next Draw");
    setSecs(seconds % 60);
    setMins(parseInt((seconds % 3600) / 60));
    setHours(parseInt((seconds % 86400) / 3600));
    setDays(parseInt((seconds % 2073600) / 86400));
  }


  // This initializes secondsToRebase as soon as currentBlock becomes available
  useMemo(() => {
    if (currentBlock) {
      initializeTimer();
    }
  }, [currentBlock]);


  // After every period SECONDS_TO_REFRESH, decrement secondsToRebase by SECONDS_TO_REFRESH,
  // keeping the display up to date without requiring an on chain request to update currentBlock.
  useEffect(() => {
    let interval = null;
    if (secondsToRefresh > 0) {
      interval = setInterval(() => {
        setSecondsToRefresh(secondsToRefresh => secondsToRefresh - 1);
      }, 1000);
    } else {
      // When the countdown goes negative, reload the app details and reinitialize the timer
      if (secondsToRebase < 0) {
        async function reload() {
          await dispatch(loadAppDetails({ networkID: chainID, provider: provider }));
        }
        reload();
        setRebaseString("");
      } else {
        clearInterval(interval);
        setSecondsToRebase(secondsToRebase => secondsToRebase - SECONDS_TO_REFRESH);
        setSecondsToRefresh(SECONDS_TO_REFRESH);
        const prettified = prettifySeconds(secondsToRebase);
        setRebaseString(prettified !== "" ? prettified : "Less than a minute");
        setSecs(seconds % 60);
        setMins(parseInt((seconds % 3600) / 60));
        setHours(parseInt((seconds % 86400) / 3600));
        setDays(parseInt((seconds % 2073600) / 86400));
      }
    }
    return () => clearInterval(interval);
  }, [secondsToRebase, secondsToRefresh]);

  const minuteSeconds = 60;
  const hourSeconds = 3600;
  const daySeconds = 86400;
  const timerProps = {
    isPlaying: true,
    size: 50,
    strokeWidth: 2,
  };
  const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
  const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
  const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
  const getTimeDays = (time) => (time / daySeconds) | 0;

  const renderTime = (dimension, time) => {
    return (
      <div className="time-wrapper">
        <div className="time">{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };
  var remainingTime;
  if (endTimeLeft != undefined) {
    remainingTime = Number(endTimeLeft[0]);
    if (remainingTime == NaN) {
      remainingTime = 0;
    }
  } else {
    remainingTime = 0;
  }
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  return (
    <Box className="rebase-timer">
      <Typography variant="body2">
        {currentBlock ? (
          secondsToRebase > 0 ? (
            <>
              {/* <strong>{rebaseString}</strong> */}

              <div className="Timer">
                <CountdownCircleTimer
                  {...timerProps}
                  colors="#ed8c79"
                  duration={daysDuration}
                  initialRemainingTime={seconds}
                >
                  {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                      {renderTime("days", getTimeDays(daysDuration - elapsedTime))}
                    </span>
                  )}
                </CountdownCircleTimer>
                <CountdownCircleTimer
                  {...timerProps}
                  colors="#ec6572"
                  duration={daySeconds}
                  initialRemainingTime={remainingTime % daySeconds}
                  onComplete={(totalElapsedTime) => ({
                    shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
                  })}
                >
                  {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                      {renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
                    </span>
                  )}
                </CountdownCircleTimer>
                <CountdownCircleTimer
                  {...timerProps}
                  colors="#b065a9"
                  duration={hourSeconds}
                  initialRemainingTime={remainingTime % hourSeconds}
                  onComplete={(totalElapsedTime) => ({
                    shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
                  })}
                >
                  {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                      {renderTime("mins", getTimeMinutes(hourSeconds - elapsedTime))}
                    </span>
                  )}
                </CountdownCircleTimer>
                <CountdownCircleTimer
                  {...timerProps}
                  colors="#7daa6a"
                  duration={minuteSeconds}
                  initialRemainingTime={remainingTime % minuteSeconds}
                  onComplete={(totalElapsedTime) => ({
                    shouldRepeat: remainingTime - totalElapsedTime > 0
                  })}
                >
                  {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                      {renderTime("secs", getTimeSeconds(elapsedTime))}
                    </span>
                  )}
                </CountdownCircleTimer>
              </div>

            </>
          ) : (
            <strong>Drawing</strong>
          )
        ) : (
          <Skeleton width="155px" />
        )}
      </Typography>
    </Box>
  );
}

export default RebaseTimer2;





