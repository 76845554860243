import Outfitttf from "../assets/fonts/Outfit-Regular.ttf";
import OutfitBoldttf from "../assets/fonts/Outfit-VariableFont_wght.ttf";
import OutfitSemiBoldttf from "../assets/fonts/Outfit-SemiBold.ttf";
import OutfitItalicttf from "../assets/fonts/Outfit-ExtraLight.ttf";
import OutfitLightttf from "../assets/fonts/Outfit-Light.ttf";
import OutfitMediumttf from "../assets/fonts/Outfit-Medium.ttf";

const Outfit = {
    fontFamily: "Outfit",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `
		local('Outfit'),
		local('Outfit-Regular'),
		url(${Outfitttf}) format('ttf')
	`,
    unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const OutfitLight = {
    fontFamily: "Outfit",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `
		local('Outfit'),
		local('Outfit-Light'),
		url(${OutfitLightttf}) format('ttf')
	`,
    unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const OutfitMedium = {
    fontFamily: "Outfit",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 500,
    src: `
		local('Outfit'),
		local('Outfit-Medium'),
		url(${OutfitMediumttf}) format('ttf')
	`,
    unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const OutfitSemiBold = {
    fontFamily: "Outfit",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 500,
    src: `
		local('Outfit-SemiBold'),
		local('Outfit-SemiBold'),
		url(${OutfitSemiBoldttf}) format('ttf')
	`,
    unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const OutfitBold = {
    fontFamily: "Outfit",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 500,
    src: `
		local('Outfit-Bold'),
		local('Outfit-Bold'),
		url(${OutfitBoldttf}) format('ttf')
	`,
    unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const OutfitItalic = {
    fontFamily: "Outfit",
    fontStyle: "italic",
    fontDisplay: "swap",
    fontWeight: 500,
    src: `
		local('Outfit-Italic'),
		local('Outfit-Italic'),
		url(${OutfitItalicttf}) format('ttf')
	`,
    unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const fonts = [Outfit];

export default fonts;