import namor from "namor";


const range = (len) => {
  const arr = [];
  if(len.length > 0)
  {
    len = len.length;
  }
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newPerson = (len1, lens2, lens3) => {

  const date = new Date(lens2 * 1000);
  var curdate = parseInt(Date.now() / 1000);
  let result;
  if (lens3 != undefined) {
    result = lens3[3];
  }else{
    return false;
  }
  return {
    firstName: len1,
    lastName:
      lens2 > curdate
        ? "Pending now"
        : "Finished",

    age: date.toLocaleDateString("en-US"),
    visits:
      Number(result[0]) == len1
        ? "win 1st"
        : Number(result[1]) == len1
          ? "win 2nd"
          : Number(result[2]) == len1
            ? "win 3th"
            : Number(result[3]) == len1
              ? "win 4th"
              : Number(result[4]) == len1
                ? "win 5th"
                : "lost",
    progress:
      Number(result) == len1
        ? "win"
        : "lost",
    status:
      Number(result) == len1
        ? "win"
        : "lost"
    // statusChange > 0.66
    //   ? "relationship"
    //   : statusChange > 0.33
    //     ? "complicated"
    //     : "single"
  };
};

export default function makeData(...lens) {

  const makeDataLevel = (depth = 0) => {
    const len = lens[depth];
    return range(len).map((d) => {
      return {
        ...newPerson(lens[1][d], lens[2], lens[3]),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined
      };
    });
  };

  return makeDataLevel();
}
