import { useEffect, useState } from "react";
import { Paper, Grid, Typography, Box, Zoom, Container, SvgIcon, useMediaQuery } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { ReactComponent as HowtoplayIcon } from "../../assets/icons/howtoplay.svg";
import {
  treasuryDataQuery,
  rebasesDataQuery,
  bulletpoints,
  tooltipItems,
  tooltipInfoMessages,
  itemType,
} from "./treasuryData.js";
import { useTheme } from "@material-ui/core/styles";
import "../Home/main.scss"
import apollo from "../../lib/apolloClient";

// import RebaseTimer from "../../components/RebaseTimer/RebaseTimer";

function HomeDashboard() {
  const [data, setData] = useState(null);
  const [apy, setApy] = useState(null);
  const [runway, setRunway] = useState(null);
  const [staked, setStaked] = useState(null);
  const theme = useTheme();
  const smallerScreen = useMediaQuery("(max-width: 650px)");
  const verySmallScreen = useMediaQuery("(max-width: 379px)");


  const NumberOfTicketsSold = useSelector(state => {
    return state.app.NumberofTicketSold;
  });

  const rewardAmount =  useSelector(state => {
    return state.app.rewardAmount;
  });
  let rewardAmountWinner = Number(rewardAmount)/1000000000000000000 ;




  useEffect(() => {
    apollo(treasuryDataQuery).then(r => {
      let metrics = r.data.protocolMetrics.map(entry =>
        Object.entries(entry).reduce((obj, [key, value]) => ((obj[key] = parseFloat(value)), obj), {}),
      );
      metrics = metrics.filter(pm => pm.treasuryMarketValue > 0);
      setData(metrics);

      let staked = r.data.protocolMetrics.map(entry => ({
        staked: (parseFloat(entry.sOhmCirculatingSupply) / parseFloat(entry.ohmCirculatingSupply)) * 100,
        timestamp: entry.timestamp,
      }));
      staked = staked.filter(pm => pm.staked < 100);
      setStaked(staked);

      let runway = metrics.filter(pm => pm.runway10k > 5);
      setRunway(runway);
    });

    apollo(rebasesDataQuery).then(r => {
      let apy = r.data.rebases.map(entry => ({
        apy: Math.pow(parseFloat(entry.percentage) + 1, 365 * 3) * 100,
        timestamp: entry.timestamp,
      }));

      apy = apy.filter(pm => pm.apy < 300000);

      setApy(apy);
    });
  }, []);

  return (
    <div id="treasury-dashboard-view" className={`${smallerScreen && "smaller"} ${verySmallScreen && "very-small"}`}>
      <Container
        style={{
          paddingLeft: smallerScreen || verySmallScreen ? "0" : "3.3rem",
          paddingRight: smallerScreen || verySmallScreen ? "0" : "3.3rem",
        }}
      >
        <Box className={`hero-metrics`}>
          <Paper className="ohm-card">

          <Box className="empty-card">
             {/* <SvgIcon
               color="primary"
               component={HowtoplayIcon}
               viewBox="0 0 1280 720"
               style={{ width: "300px", height: "120px", justifyContent: "center", display:"flex" ,alignContent:"center", alignItems:"center"}}
             /> */}
          </Box>            

          <Box className='medium-card'>
              {/* <Box className="small-card-top">
              <h1 className="title">Next Draw in</h1>                
                <RebaseTimer />
              </Box> */}

              <Box className="small-card-middle2">
              <h1 className="title">Total Prizes</h1>
              <h2 className="price">{rewardAmountWinner} DAI</h2>
              </Box>
          </Box>

          <Box className="small-card-bottom">

          <h1 className="title">Ticket Sold</h1>
          <h2 className="price">
                      {NumberOfTicketsSold ? (
                        parseInt(NumberOfTicketsSold)
                      ) : (
                        0
                      )}
          </h2>
          </Box>                    
 
          <div className="single-button">
            <a href="../#/buyticket" class='buybutton_home'>Join Now</a>
          </div>
          </Paper>            
        </Box>
      </Container>

      <Box className="margin-box"> 
      </Box>

      <Container
        style={{
            paddingLeft: smallerScreen || verySmallScreen ? "0" : "3.3rem",
            paddingRight: smallerScreen || verySmallScreen ? "0" : "3.3rem",
            }}
         >
        <Paper className="ohm-card">
            <h1 className="title" style={{ marginBottom: "0.75rem"}}>How to Play </h1>
            <Typography style={{ color: "#ed8c79", textAlign:"center", lineHeight: "1", paddingLeft: "0.5rem",  paddingRight: "0.5rem" }}>
            ChitFund is a game for you to try your luck. 
            </Typography>
            <Typography style={{ color: "#ed8c79", marginTop: "0.5rem", textAlign:"center", lineHeight: "1", paddingLeft: "0.5rem",  paddingRight: "0.5rem"}}>
            2% of the total ticket purchases will be allocated for the development fund and/or coin burn. 
            </Typography>

            <Typography style={{ color: "#ed8c79", marginTop: "0.5rem", textAlign:"center", lineHeight: "1", paddingLeft: "0.5rem",  paddingRight: "0.5rem"}}>
            The remaining 98% will be awarded to lucky winner.
            </Typography>

            <Box className={`hero-metrics`}>
              <Box className="qa-card-top">
                <h1 className="titleqa" style={{ fontSize: "24px"}}>1. Connect to Your Wallet</h1>                
                <Typography style={{ lineHeight: "1.1"}}>
                  Connect to your wallet using the "Connect Wallet" button a the upper right corner above. Make sure to connect to ETH Mainnet in order to see your DAI balances.
                </Typography>              
              </Box>
              <Box className="qa-card">
                <h1 className="titleqa" style={{ fontSize: "24px"}}>2. Get DAI</h1>                    
                <Typography style={{ lineHeight: "1.1"}}>
                  You need DAI Token to play. Simply swap ETH to DAI or vice versa at UniSwap by clicking the button below.
                </Typography>
              </Box>
              <div className="wrapped-swap-button">
                    <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xdc31ee1784292379fbb2964b3b9c4124d8f89c60" target="_blank" class='buybutton_home'>Swap ETH to DAI</a> 
              </div>              
              <Box className="qa-card">
                <h1 className="titleqa" style={{ fontSize: "24px"}}>3. Buy Tickets</h1>
                <Typography style={{ lineHeight: "1.1"}}>
                  Navigate to “Buy Ticket” on the left sidebar. Enter a number of tickets you would like to purchase for the next draw. The purchases are paid in DAI Token.             
                </Typography>
              </Box>
              <Box className="qa-card-bottom">
                <h1 className="titleqa" style={{ fontSize: "24px"}}>4. Check Results</h1>
                <Typography style={{ lineHeight: "1.1"}}>
                  Navigate to “My Tickets” on the left sidebar to see your ticket balance and results of your purchased tickets in the previous rounds. If you are a winner, the prize in DAI Token will be automatically sent to your wallet.
                </Typography>
              </Box>
            </Box>
        </Paper>
      </Container>
      <Box className="margin-box"> 
      </Box>
    </div>
  );
}

export default HomeDashboard;
