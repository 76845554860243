import { useState } from "react";
import * as React from "react";
import { Paper, Grid, Typography, Box, Zoom, Container, SvgIcon, useMediaQuery } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { ReactComponent as WinnersIcon } from "../../assets/icons/winners.svg";
import {
  treasuryDataQuery,
  rebasesDataQuery,
  bulletpoints,
  tooltipItems,
  tooltipInfoMessages,
  itemType,
} from "./treasuryData.js";
import { useTheme } from "@material-ui/core/styles";
import "../Home/main.scss"
import apollo from "../../lib/apolloClient";

// import RebaseTimer from "../../components/RebaseTimer/RebaseTimer";
import { useTable } from 'react-table';
import styled from 'styled-components';

import makeData from './makeData';


const Styles = styled.div`
  width : 100%;
  margin: auto;
  overflow-x : auto;
  margin-bottom: 0rem;

  table {
    width : 100%;
    margin: auto;
    font-weight: 400;
    font-size: 14px;
    background: linear-gradient(90deg, #071320 0%, #0d2d3c 20%, #314d58 40%, #314d58 60%, #0d2d3c 80%, #071320 100%);
    border-spacing: 0;
    border-radius: 1.5rem;
    border-radius: 0 0 1.5rem 1.5rem;
    z-index: 100;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      color: #7daa6a;
      font-weight: 300;
      padding: 0.5rem;
      border-top: 1px solid #071320;
      border-bottom: 0px solid #071320;
      border-right: 1px solid #071320;
      z-index: 99;

      :last-child {
        border-right: 0;

      }
    }
  }
`


function HistoryDashboard() {

  const columns = React.useMemo(
    () => [



      {
        Header: 'TICKET NO',
        accessor: 'firstName',
      },
      {
        Header: 'WALLET ADDRESS',
        accessor: 'walletaddress',
      },
      {
        Header: 'TOKEN REWARD AMOUNT',
        accessor: 'lastName',
      },
      {
        Header: 'DATE',
        accessor: 'age',
      },




    ],
    []
  )


  const [data, setData] = useState(null);
  const [apy, setApy] = useState(null);
  const [runway, setRunway] = useState(null);
  const [staked, setStaked] = useState(null);
  const theme = useTheme();
  const smallerScreen = useMediaQuery("(max-width: 650px)");
  const verySmallScreen = useMediaQuery("(max-width: 379px)");


  const NumberOfTicketsSold = useSelector(state => {
    return state.app.NumberofTicketSold;
  });

  const accountTicketbalance = useSelector(state => {
    return state.account.accountTicketbalance;
  });

  const accountTicketID = useSelector(state => {
    return state.account.accountTicketID;
  });
  // const endTime = useSelector(state => {
  //   return state.app.epoch;
  // });
  const history = useSelector(state => {
    return state.app.history;
  });
  const rewardAmount =  useSelector(state => {
    return state.app.rewardAmount;
  });
  let rewardAmountWinner = Number(rewardAmount)/1000000000000000000 ;
  let historyID = [];
  let historyWallet = [];
  let historyAmount = [];
  let historyDate = [];
  var TableData;

  if (history !== undefined) {
    for(let i = 0; i< history[3].length; i++)
    {
      historyID.push(Number(history[3][i]));
      historyWallet.push(history[0][i]);
      historyAmount.push(Number(history[2][i])/1000000000000000000);
      historyDate.push(Number(history[1][i]));
    }
    TableData = makeData(history[0].length, historyID, historyWallet, historyAmount, historyDate);
  } else {
    TableData = makeData(0);
  }
  return (

    <div id="treasury-dashboard-view" className={`${smallerScreen && "smaller"} ${verySmallScreen && "very-small"}`}>
      <Container
        style={{
          paddingLeft: smallerScreen || verySmallScreen ? "0" : "3.3rem",
          paddingRight: smallerScreen || verySmallScreen ? "0" : "3.3rem",
        }}
      >

        <Box className={`hero-metrics`}>
          <Paper className="ohm-card">

          <Box className="empty-card">
             {/* <SvgIcon
               color="primary"
               component={WinnersIcon}
               viewBox="0 0 1280 720"
               style={{ width: "300px", height: "120px", justifyContent: "center", display:"flex" ,alignContent:"center", alignItems:"center"}}
             /> */}
          </Box>



          <Box className='medium-card'>
              {/* <Box className="small-card-top">
              <h1 className="title">Next Draw in</h1>                
                <RebaseTimer />
              </Box> */}

              <Box className="small-card-middle2">
              <h1 className="title">Total Prizes</h1>
              <h2 className="price">{rewardAmountWinner} DAI</h2>
              </Box>

              <Box className="small-card-middle3">
              <h1 className="titlebottom">Winners</h1>
              </Box>


          </Box>
    
            <Styles>
              <Table columns={columns} data={TableData} />
            </Styles>
          </Paper>

        </Box>

      </Container>
    </div>
  );
}


function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns, data
  })
  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default HistoryDashboard;
