import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import Social from "./Social";
import externalUrls from "./externalUrls";
import { ReactComponent as ConvertIcon } from "../../assets/icons/token_HOM.svg"
import { ReactComponent as StakeIcon } from "../../assets/icons/stake.svg";
import { ReactComponent as BondIcon } from "../../assets/icons/bond.svg";
import { ReactComponent as GlobeIcon } from "../../assets/icons/globe.svg";
import { ReactComponent as SunIcon } from "../../assets/icons/sun.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as ChartIcon } from "../../assets/icons/chart.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/logoicon.svg";
import { ReactComponent as WalletIcon } from "../../assets/icons/walleticon.svg";
import { ReactComponent as HowtoplayIcon } from "../../assets/icons/howtoplayicon.svg";
import { ReactComponent as WinnerIcon } from "../../assets/icons/winnericon.svg";
import { ReactComponent as BuyIcon } from "../../assets/icons/buyicon.svg";
import { ReactComponent as PrizeIcon } from "../../assets/icons/prizeicon.svg";
import { ReactComponent as TicketIcon } from "../../assets/icons/ticketicon.svg";

import { trim, shorten } from "../../helpers";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import useBonds from "../../hooks/Bonds";
import { Paper, Link, Box, Typography, SvgIcon, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import "./sidebar.scss";
import styled from 'styled-components';

import OhmImg from "src/assets/tokens/Wand.png";

const StyledSvgIcon = styled(SvgIcon)`
  color: inherit;
  align-items: center;
`

function NavContent() {
  const [isActive] = useState();
  const address = useAddress();
  const { bonds } = useBonds();
  const { chainID } = useWeb3Context();

  const checkPage = useCallback((match, location, page) => {
    const currentPath = location.pathname.replace("/", "");
    if (currentPath.indexOf("home") >= 0 && page === "home") {
      return true;
    }
    if (currentPath.indexOf("mywallet") >= 0 && page === "mywallet") {
      return true;
    }
    if (currentPath.indexOf("ticketwallet_history") >= 0 && page === "ticketwallet_history") {
      return true;
    }
    if (currentPath.indexOf("ticketwallet_winning") >= 0 && page === "ticketwallet_winning") {
      return true;
    }
    // if (currentPath.indexOf("freedraw") >= 0 && page === "freedraw") {
    //   return true;
    // }
    if (currentPath.indexOf("buyticket") >= 0 && page === "buyticket") {
      return true;
    }
    if (currentPath.indexOf("howtoplay") >= 0 && page === "howtoplay") {
      return true;
    }
    if ((currentPath.indexOf("bonds") >= 0 || currentPath.indexOf("choose_bond") >= 0) && page === "bonds") {
      return true;
    }
    if ((currentPath.indexOf("calculator") >= 0 && page === "calculator")) {
      return true;
    }
    return false;
  }, []);
  // let ca=document.getElementsByClassName("app");
  // console.log('ca',ca)


  return (
    <Paper className="dapp-sidebar">
      <Box className="dapp-sidebar-inner" display="flex" justifyContent="space-between" flexDirection="column">
        <div className="dapp-menu-top">
          <Box className="branding-header">
            <Link href="/" target="_blank">
              <SvgIcon
                color="primary"
                component={LogoIcon}
                viewBox="0 0 1280 1024"
                style={{ minWidth: "240px", marginTop:"2rem", marginBottom: "-1rem", minHeight: "180px", display:"flex" ,alignContent:"center", alignItems:"center"}}
              />
            </Link>
           
 {/*           <h1 style={{"marginTop":"0.5  rem", "marginBottom":"0rem", fontWeight:"500", letterSpacing:"2px", "color":"#ffffff"}}>ChitFund JACKPOT</h1>  */}

          <h1 className="titlebrand">Ekub</h1>
          {/* <h1 className="title">Lucky Draw</h1> */}
            {address && (
              <div className="wallet-link">
                <Link href={`https://goerli.etherscan.io/address/${address}`} target="_blank">

                  {shorten(address)}
                </Link>
              </div>
            )}
          </Box>
          <div className="dapp-menu-links">
            <div className="dapp-nav" id="navbarNav">

              <Link
                component={NavLink}
                id="dash-nav"
                to="/home"
                isActive={(match, location) => {
                  return checkPage(match, location, "home");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
              {/*    <img src='../home.png' className="iconimage"/> */}
                <StyledSvgIcon component={PrizeIcon} />  
                <Typography variant="h6">
                  Prizes
                </Typography>
              </Link>
              {/* <Link
                component={NavLink}
                id="dash-nav"
                to="/portfolio"
                isActive={(match, location) => {
                  return checkPage(match, location, "portfolio");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography variant="h6">
                  <SvgIcon color="primary" component={ChartIcon} />
                  Portfolio
                </Typography>
              </Link> */}
              {/* <Link
                component={NavLink}
                id="convert-nav"
                to="/convert"
                isActive={(match, location) => {
                  return checkPage(match, location, "convert");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography variant="h6">
                  <SvgIcon color="primary" component={ConvertIcon} />
                  Conversion
                </Typography>
              </Link> */}


              <Link
                component={NavLink}
                id="dash-nav"
                to="/mywallet"
                isActive={(match, location) => {
                  return checkPage(match, location, "mywallet");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
              {/*  <img src='../wallet.png' className="iconimage" /> */}
                <Typography variant="h6">
                <StyledSvgIcon component={WalletIcon} />
                  My Wallet
                </Typography>
              </Link>

              {/* <Link
                component={NavLink}
                id="dash-nav"
                to="/ticketwallet_history"
                isActive={(match, location) => {
                  return checkPage(match, location, "ticketwallet_history");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
           
                <Typography variant="h6">
                <StyledSvgIcon component={TicketIcon} />
                  My Tickets
                </Typography>
              </Link> */}

              <Link
                component={NavLink}
                id="dash-nav"
                to="/ticketwallet_winning"
                isActive={(match, location) => {
                  return checkPage(match, location, "ticketwallet_winning");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
              {/*    <img src='../WinningNumbersIcon.png' className="iconimage" /> */}

               <Typography variant="h6">
                <StyledSvgIcon component={WinnerIcon} />
                  Winners
                </Typography>
              </Link>
{/* 
              <Link
                component={NavLink}
                id="dash-nav"
                to="/freedraw"
                isActive={(match, location) => {
                  return checkPage(match, location, "freedraw");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <img src='../notifications.png' className="iconimage" />
                <Typography variant="h6">

                  FREE DRAW
                </Typography>
              </Link> */}


              <Link
                component={NavLink}
                id="stake-nav"
                to="/buyticket"
                isActive={(match, location) => {
                  return checkPage(match, location, "buyticket");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
              {/*    <img src='../ticket.png' className="iconimage" /> */}
                <Typography variant="h6">
                <StyledSvgIcon component={BuyIcon} />
                  Join Chitfund
                </Typography>
              </Link>


              <Link
                component={NavLink}
                id="dash-nav"
                to="/howtoplay"
                isActive={(match, location) => {
                  return checkPage(match, location, "howtoplay");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
              {/*    <img src='../media.png' className="iconimage" /> */}
                <Typography variant="h6">
                <StyledSvgIcon component={HowtoplayIcon} />
                  How to Play
                </Typography>
              </Link>
            </div>
          </div>
        </div>
        <Box className="dapp-menu-bottom" display="flex" justifyContent="space-between" flexDirection="column">
          <div className="dapp-menu-external-links">
            {externalUrls.map(({ url, icon, title, label }, i) => {
              return (
                <Link key={i} href={url} target="_blank" component={url ? "a" : "span"}>
                  <Typography variant="h6">{icon}</Typography>
                  <Typography variant="h6">{title}</Typography>
                  {label ? (
                    <Typography variant="caption" style={{ marginLeft: "8px" }}>
                      {label}
                    </Typography>
                  ) : null}
                </Link>
              );
            })}
          </div>
          <div className="dapp-menu-social">
            <Social />
          </div>
        </Box>
      </Box>
    </Paper>
  );
}

export default NavContent;
