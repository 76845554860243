import { useEffect, useState } from "react";
import { Paper, Grid, Typography, Box, Zoom, Container, SvgIcon, useMediaQuery } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { ReactComponent as PrizeIcon } from "../../assets/icons/prizes.svg";
import {
  treasuryDataQuery,
  rebasesDataQuery,
  bulletpoints,
  tooltipItems,
  tooltipInfoMessages,
  itemType,
} from "./treasuryData.js";
import { useTheme } from "@material-ui/core/styles";
import "../Home/main.scss"
import apollo from "../../lib/apolloClient";

// import RebaseTimer from "../../components/RebaseTimer/RebaseTimer";

function HomeDashboard() {
  const [data, setData] = useState(null);
  const [apy, setApy] = useState(null);
  const [runway, setRunway] = useState(null);
  const [staked, setStaked] = useState(null);
  const theme = useTheme();
  const smallerScreen = useMediaQuery("(max-width: 650px)");
  const verySmallScreen = useMediaQuery("(max-width: 379px)");
  const NumberOfTicketsSold = useSelector(state => {
    return state.app.NumberofTicketSold;
  });

  const rewardAmount = useSelector(state => {
    return state.app.rewardAmount;
  });
  let rewardAmountWinner = Number(rewardAmount) / 1000000000000000000;

  useEffect(() => {
    apollo(treasuryDataQuery).then(r => {
      let metrics = r.data.protocolMetrics.map(entry =>
        Object.entries(entry).reduce((obj, [key, value]) => ((obj[key] = parseFloat(value)), obj), {}),
      );
      metrics = metrics.filter(pm => pm.treasuryMarketValue > 0);
      setData(metrics);

      let staked = r.data.protocolMetrics.map(entry => ({
        staked: (parseFloat(entry.sOhmCirculatingSupply) / parseFloat(entry.ohmCirculatingSupply)) * 100,
        timestamp: entry.timestamp,
      }));
      staked = staked.filter(pm => pm.staked < 100);
      setStaked(staked);

      let runway = metrics.filter(pm => pm.runway10k > 5);
      setRunway(runway);
    });

    apollo(rebasesDataQuery).then(r => {
      let apy = r.data.rebases.map(entry => ({
        apy: Math.pow(parseFloat(entry.percentage) + 1, 365 * 3) * 100,
        timestamp: entry.timestamp,
      }));

      apy = apy.filter(pm => pm.apy < 300000);

      setApy(apy);
    });
  }, []);

  return (

    <div id="treasury-dashboard-view" className={`${smallerScreen && "smaller"} ${verySmallScreen && "very-small"}`}>
      <Container
        style={{
          paddingLeft: smallerScreen || verySmallScreen ? "0" : "3.3rem",
          paddingRight: smallerScreen || verySmallScreen ? "0" : "3.3rem",
        }}
      >
        <Box className={`hero-metrics`}>
          <Paper className="ohm-card">

            <Box className="empty-card">
              {/* <SvgIcon
               color="primary"
               component={PrizeIcon}
               viewBox="0 0 1280 720"
               style={{ width: "290px", height: "120px", display:"flex" ,alignContent:"center", alignItems:"center"}}
             /> */}
            </Box>

            <Box className='medium-card'>
              {/* <Box className="small-card-top">
                <h1 className="title">Next Draw in</h1>
                <RebaseTimer />
              </Box> */}
              <Box className="small-card-middle">
                <h1 className="title">Total Prizes</h1>
                <h2 className="price">
                  {rewardAmountWinner ? (
                    parseInt(rewardAmountWinner)
                  ) : (
                    0
                  )}
                  &nbsp; DAI</h2>
              </Box>
              <Box className="small-card-bottom">
                <h1 className="title">Tickets Sold</h1>
                <h2 className="price">
                  {NumberOfTicketsSold ? (
                    parseInt(NumberOfTicketsSold)
                  ) : (
                    0
                  )}
                </h2>
              </Box>
            </Box>

            <div className="single-button">
              <a href="../#/buyticket" class='buybutton_home'>Join Now</a>
            </div>

          </Paper>
        </Box>
      </Container>
      {/* <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={350} />
      </div> */}
    </div>
  );
}

export default HomeDashboard;
