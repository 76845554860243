const range = (len) => {
  const arr = [];
  if(len.length > 0)
  {
    len = len.length;
  }
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newPerson = (len1, len2, len3, len4) => {
  const date = new Date(len4 * 1000);
  return {
    firstName: len1,
    walletaddress:len2,
    lastName:"$ " + len3,
    age: date.toLocaleDateString("en-US")
  };
};

export default function makeData(...lens) {

  const makeDataLevel = (depth = 0) => {
    const len = lens[depth];
    return range(len).map((d) => {
      return {
        ...newPerson(lens[1][d], lens[2][d], lens[3][d], lens[4][d]),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined
      };
    });
  };

  return makeDataLevel();
}
