import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20Abi } from "../abi/IERC20.json";
// import { abi as OlympusStaking } from "../abi/OlympusStakingv2.json";
// import { abi as ConvertAbi } from "../abi/PresaleTokenConvert.json";
// import { abi as StakingHelper } from "../abi/StakingHelper.json";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountSuccess, getBalances, loadAccountDetails } from "./AccountSlice";
import { loadAppDetails } from "./AppSlice";
import { error, info } from "./MessagesSlice";
import { IActionValueAsyncThunk, IChangeApprovalAsyncThunk, IJsonRPCError } from "./interfaces";
// import { segmentUA } from "../helpers/userAnalyticHelpers";
import { abi as luckblock } from "../abi/luckblock.json";

interface IUAData {
  address: string;
  value: string;
  approved: boolean;
  txHash: string | null;
  type: string | null;
}

const MaxUint256 =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

function alreadyApprovedToken(token: string, buyAllowance: BigNumber) {
  // set defaults
  let bigZero = BigNumber.from("100000000000000000000");
  let applicableAllowance = bigZero;
  // determine which allowance to check
  if (token === "dai") {
    applicableAllowance = buyAllowance;
  }
  // check if allowance exists
  if (applicableAllowance.gt(bigZero)) return true;
  return false;
}

export const changeApproval = createAsyncThunk(
  "stake/changeApproval",
  async ({ token, poolId, provider, address, networkID }: IChangeApprovalAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider.getSigner();
    const lbtokencontract = new ethers.Contract(addresses[networkID].CHITTOKEN_ADDRESS as string, ierc20Abi, signer);
    let buyAllowance =  await lbtokencontract.allowance(address, addresses[networkID].CHITFUND_ADDRESS);
    let approveTx;
    // return early if approval has already happened
    // if (alreadyApprovedToken(token, buyAllowance)) {
    //   dispatch(info("Approval completed."));
    //   return dispatch(
    //     fetchAccountSuccess({
    //       staking: {
    //         buyAllowance: +buyAllowance
    //       },
    //     }),
    //   );
    // }
    try {
      if (token === "dai" && poolId == 0) {
        approveTx = await lbtokencontract.approve(
          addresses[networkID].CHITFUND_ADDRESS,
          "100000000000000000000"
        );
      }else if (token === "dai" && poolId == 1) {
        approveTx = await lbtokencontract.approve(
          addresses[networkID].CHITFUND_ADDRESS,
          "200000000000000000000"
        );
      }else if (token === "dai" && poolId == 2) {
        approveTx = await lbtokencontract.approve(
          addresses[networkID].CHITFUND_ADDRESS,
          "300000000000000000000"
        );
      }
      const text =  "Approving";
      const pendingTxnType = "approve_buying";
      dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
      await approveTx.wait();
    } catch (e: unknown) {
      // dispatch(error((e as IJsonRPCError).message));
      dispatch(info("Project is setting now, Please try again later"))
      console.log('debug approve error', e);
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
    buyAllowance =  await lbtokencontract.allowance(address, addresses[networkID].CHITFUND_ADDRESS);
    await dispatch(loadAccountDetails({ networkID, address, provider }));
    return dispatch(
      fetchAccountSuccess({
        staking: {
          buyAllowance: +buyAllowance
        },
      }),
    );
  },
);

export const changeBuy = createAsyncThunk(
  "stake/changeBuy",
  async ({ action, provider, address, networkID, poolId }: IActionValueAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider.getSigner();
    const lbtokencontract = new ethers.Contract(addresses[networkID].CHITTOKEN_ADDRESS as string, ierc20Abi, signer);
    let balance =  await lbtokencontract.balanceOf(address);
    console.log('debug->balance', balance / 100000000000000000)
    let luckblockContract = new ethers.Contract(addresses[networkID].CHITFUND_ADDRESS as string, luckblock, signer)
    let buyTx;
    try {
      if(poolId == "0" && (balance / 10000000000000000) < 100 || poolId == "1" && (balance / 10000000000000000) < 200 || poolId == "2" && (balance / 10000000000000000) < 300)
      {
        dispatch(
          error("You have no full DAI balance for this"),
        );
        return;
      }
      buyTx = await luckblockContract.buyTicket(poolId);
      const text ="Buying";
      const pendingTxnType = "buying";
      dispatch(fetchPendingTxns({ txnHash: buyTx.hash, text, type: pendingTxnType }));
      await buyTx.wait();
      await new Promise<void>((resolve, reject) => {
        setTimeout(async () => {
          try {
            await dispatch(loadAccountDetails({ networkID, address, provider }));
            await  dispatch(loadAppDetails({ networkID, provider }));
            resolve();
          } catch (error) {
            reject(error);
          }
        }, 1000);
      });
    } catch (e: unknown) {
      const rpcError = e as IJsonRPCError;
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          info("You may be trying to purchase more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else {
        // dispatch(error(rpcError.message));
        dispatch(info("Project is setting now, Please try again later"))
      }
      return;
    } finally {
      if (buyTx) {
        dispatch(clearPendingTxn(buyTx.hash));
      }
    }
    dispatch(getBalances({ address, networkID, provider }));
  },
);