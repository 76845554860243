import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  // Link,
  OutlinedInput,
  Paper,
  // Tab,
  // Tabs,
  // SvgIcon,
  Typography,
  // Zoom,
} from "@material-ui/core";
// import NewReleases from "@material-ui/icons/NewReleases";
import RebaseTimer1 from "../../components/RebaseTimer/RebaseTimer1";
import RebaseTimer2 from "../../components/RebaseTimer/RebaseTimer2";
import RebaseTimer3 from "../../components/RebaseTimer/RebaseTimer3";
import TabPanel from "../../components/TabPanel";
// import { getOhmTokenImage, getTokenImage, trim } from "../../helpers";
import { changeApproval, changeBuy } from "../../slices/BuyThunk";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./stake.scss";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@material-ui/lab";
// import ExternalStakePool from "./ExternalStakePool";
import { error } from "../../slices/MessagesSlice";
// import { ethers, BigNumber } from "ethers";
// import { ReactComponent as TicketIcon } from "../../assets/icons/ticket.svg";
import styled from 'styled-components';

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

const StyledButton = styled(Button)`	
  color: #d4af34 !important;	
  :hover {	
    color: #b29700 !important;	
  },	
  :active {	
    color: #b29700;	
  }	
`

const StyledConnectButton = styled(Button)`	
  margin-top: 0.5rem !important;
  width: 100% !important;
`

function Stake() {
  const dispatch = useDispatch();
  const { provider, address, connected, connect, chainID } = useWeb3Context();

  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState("");

  const buyAllowance = useSelector(state => {
    return state.account.buyAllowance;
  });
  const accountTicketID = useSelector(state => {
    return state.account.accountTicketID;
  });
  const pool1AccountTicketbalance = useSelector(state => {
    return state.account.pool1AccountTicketbalance;
  });
  const pool2AccountTicketbalance = useSelector(state => {
    return state.account.pool2AccountTicketbalance;
  });
  const pool3AccountTicketbalance = useSelector(state => {
    return state.account.pool3AccountTicketbalance;
  });
  const rewardAmount = useSelector(state => {
    return state.app.rewardAmount;
  });
  let rewardAmountWinner = Number(rewardAmount) / 1000000000000000000;

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });
  // const getTimeLeft = useSelector(state => {
  //   return state.app.getTimeLeft;
  // });

  const onSeekApproval = async (token, poolId) => {
    await dispatch(changeApproval({ address, token, poolId, provider, networkID: chainID }));
  };

  const onChangeStake = async (action, poolId) => {
    // eslint-disable-next-line no-restricted-globals
    // if (getTimeLeft == 0) {
    //   return dispatch(error("The Draw has not started yet."));
    // }

    // 1st catch if quantity > balance
    await dispatch(
      changeBuy({
        address,
        action,
        provider,
        networkID: chainID,
        poolId
      }),
    );
  };

  let modalButton = [];
  modalButton.push(
    <StyledConnectButton variant="contained" color="primary" className="connect-button" onClick={connect} key={1}>
      Connect Wallet
    </StyledConnectButton>,
  );

  const NumberOfTicketsSold = useSelector(state => {
    return state.app.NumberofTicketSold;
  });

  return (
    <>
      <div id="stake-view">
        <Paper className={`ohm-card`}>
          <Box className="empty-card">
            {/* <SvgIcon
              color="primary"
              component={TicketIcon}
              viewBox="0 0 1280 720"
              style={{ width: "300px", height: "120px", justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}
            /> */}
          </Box>
          <Box className="small-card-middle2">
              <h1 className="title">Total Prizes</h1>
              <h2 className="price">{rewardAmountWinner ? (rewardAmountWinner) : '0'} DAI</h2>
            </Box>
          <Box className="small-card-middle2">
            <h1 className="title">Tickets Sold</h1>
            <h2 className="price">
              {NumberOfTicketsSold ? (
                parseInt(NumberOfTicketsSold)
              ) : (
                0
              )}
            </h2>
          </Box>

          <Box className="small-card-middle">
            <h1 className="title">My Total Ticket Balance</h1>
            <h2 className="price">
              {accountTicketID ? (
                parseInt(accountTicketID.length)
              ) : (
                0
              )}
            </h2>
          </Box>
          <h2 className="title" style={{ margin: "15px" }}>Plan 1</h2>
          <Box className='medium-card'>
            <Box className="small-card-top">
              <h1 className="title">Next Draw in</h1>
              <RebaseTimer1 />
            </Box>
          </Box>
          <Box className="small-card-bottom">
            <div style={{ display: "flex", justifyContent: 'space-evenly' }}>
              <div>
                <h1 className="title2">Ticket Price</h1>
                <h2 className="priceticket">
                  100 DAI
                </h2>
              </div>
              <div>
                <h1 className="title">My Ticket</h1>
                <h2 className="price">
                  {pool1AccountTicketbalance ? (
                    parseInt(pool1AccountTicketbalance.length)
                  ) : (
                    0
                  )}
                </h2>
              </div>
            </div>
            <Grid container direction="column" spacing={2}>
              <div className="staking-area">
                {!address ? (
                  <div className="stake-wallet-notification">
                    <div className="wallet-menu" id="wallet-menu">
                      {modalButton}
                    </div>
                    <Typography variant="h6">Connect your wallet to buy Tickets</Typography>
                  </div>
                ) : (
                  <>
                    <Box className="stake-action-area">
                      <Box className="stake-action-row " alignItems="center">
                        <TabPanel value={view} index={0} className="stake-tab-panel">
                          {address && Number(buyAllowance) / 1000000000000000000 >= 100 ? (
                            <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "buying") || parseInt(pool1AccountTicketbalance.length) > 0}
                              onClick={() => {
                                onChangeStake("buy", 0);
                              }}
                            >
                              {parseInt(pool1AccountTicketbalance.length) > 0 ? "You already purchased ticket" : txnButtonText(pendingTransactions, "joining", "Join Now")}
                            </Button>
                          ) : (
                            <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              alignItems="center"
                              alignContent="center"
                              justifyContent="center"
                              justifyItems="center"
                              disabled={isPendingTxn(pendingTransactions, "approve_buying")}
                              onClick={() => {
                                onSeekApproval("dai", 0);
                              }}
                            >
                              {txnButtonText(pendingTransactions, "approve_buying", "Approve token first for join pot")}
                            </Button>
                          )}
                        </TabPanel>
                      </Box>
                    </Box>
                  </>
                )}
              </div>
            </Grid>
          </Box>
          <h2 className="title" style={{ margin: "15px" }}>Plan 2</h2>
          <Box className='medium-card'>
            <Box className="small-card-top">
              <h1 className="title">Next Draw in</h1>
              <RebaseTimer2 />
            </Box>
          </Box>
          <Box className="small-card-bottom">
            <div style={{ display: "flex", justifyContent: 'space-evenly' }}>
              <div>
                <h1 className="title2">Ticket Price</h1>
                <h2 className="priceticket">
                  200 DAI
                </h2>
              </div>
              <div>
                <h1 className="title">My Ticket</h1>
                <h2 className="price">
                  {pool2AccountTicketbalance ? (
                    parseInt(pool2AccountTicketbalance.length)
                  ) : (
                    0
                  )}
                </h2>
              </div>
            </div>
            <Grid container direction="column" spacing={2}>
              <div className="staking-area">
                {!address ? (
                  <div className="stake-wallet-notification">
                    <div className="wallet-menu" id="wallet-menu">
                      {modalButton}
                    </div>
                    <Typography variant="h6">Connect your wallet to buy Tickets</Typography>
                  </div>
                ) : (
                  <>
                    <Box className="stake-action-area">
                      <Box className="stake-action-row " alignItems="center">
                        <TabPanel value={view} index={0} className="stake-tab-panel">
                          {address && Number(buyAllowance) / 1000000000000000000 >= 200 ? (
                              <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "buying") || parseInt(pool2AccountTicketbalance.length) > 0}
                              onClick={() => {
                                onChangeStake("buy", 1);
                              }}
                            >
                              {parseInt(pool2AccountTicketbalance.length) > 0 ? "You already purchased ticket" : txnButtonText(pendingTransactions, "joining", "Join Now")}
                            </Button>
                          ) : (
                            <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              alignItems="center"
                              alignContent="center"
                              justifyContent="center"
                              justifyItems="center"
                              disabled={isPendingTxn(pendingTransactions, "approve_buying")}
                              onClick={() => {
                                onSeekApproval("dai", 1);
                              }}
                            >
                              {txnButtonText(pendingTransactions, "approve_buying", "Approve token first for join pot")}
                            </Button>
                          )}
                        </TabPanel>
                      </Box>
                    </Box>
                  </>
                )}
              </div>
            </Grid>
          </Box>
          <h2 className="title" style={{ margin: "15px" }}>Plan 3</h2>
          <Box className='medium-card'>
            <Box className="small-card-top">
              <h1 className="title">Next Draw in</h1>
              <RebaseTimer3 />
            </Box>
          </Box>
          <Box className="small-card-bottom">
            <div style={{ display: "flex", justifyContent: 'space-evenly' }}>
              <div>
                <h1 className="title2">Ticket Price</h1>
                <h2 className="priceticket">
                  300 DAI
                </h2>
              </div>
              <div>
                <h1 className="title">My Ticket</h1>
                <h2 className="price">
                  {pool3AccountTicketbalance ? (
                    parseInt(pool3AccountTicketbalance.length)
                  ) : (
                    0
                  )}
                </h2>
              </div>
            </div>
            <Grid container direction="column" spacing={2}>
              <div className="staking-area">
                {!address ? (
                  <div className="stake-wallet-notification">
                    <div className="wallet-menu" id="wallet-menu">
                      {modalButton}
                    </div>
                    <Typography variant="h6">Connect your wallet to buy Tickets</Typography>
                  </div>
                ) : (
                  <>
                    <Box className="stake-action-area">
                      <Box className="stake-action-row " alignItems="center">
                        <TabPanel value={view} index={0} className="stake-tab-panel">
                          {address && Number(buyAllowance) / 1000000000000000000 >= 300 ? (
                              <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "buying") || parseInt(pool3AccountTicketbalance.length) > 0}
                              onClick={() => {
                                onChangeStake("buy", 2);
                              }}
                            >
                              {parseInt(pool3AccountTicketbalance.length) > 0 ? "You already purchased ticket" : txnButtonText(pendingTransactions, "joining", "Join Now")}
                            </Button>
                          ) : (
                            <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              alignItems="center"
                              alignContent="center"
                              justifyContent="center"
                              justifyItems="center"
                              disabled={isPendingTxn(pendingTransactions, "approve_buying")}
                              onClick={() => {
                                onSeekApproval("dai", 2);
                              }}
                            >
                              {txnButtonText(pendingTransactions, "approve_buying", "Approve token first for join pot")}
                            </Button>
                          )}
                        </TabPanel>
                      </Box>
                    </Box>
                  </>
                )}
              </div>
            </Grid>
          </Box>


        </Paper>
      </div>
    </>
  );
}

export default Stake;